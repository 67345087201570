@import './src/assets/vendors/jquery-3.6.4.min.js'
@import './src/assets/vendors/swiper/swiper-bundle.min.js'
@import './src/assets/vendors/stickybits/jquery.stickybits.min.js'
@import './src/assets/vendors/gumshoe/gumshoe.min.js'
// @import './src/assets/vendors/gumshoe/gumshoe.polyfills.min.js'

/*! 
 * Archea Custom JS
 */
 
(function ($) {
    
	"use strict";

	// Sliders

	var industries = new Swiper("#industries", {
		allowTouchMove: true,
		autoHeight: true,
		effect: 'fade',
	});

	$('.back-to-first-slide a').on('click', function(event) {
		event.preventDefault();
		industries.slideTo(0); // Go to the first slide
	});

	$('#industries-list g').on('click', function(event) {
		var value = $(this).data('value');
		event.preventDefault();
		industries.slideTo(value);
	});

	var team = new Swiper("#team-slider", {
		allowTouchMove: true,
		autoHeight: true
	});

	$('.tap-to-see-team').on('click', function(event) {
		event.preventDefault(event);
		team.slideNext();
	});

	// team how

	var howSlider = new Swiper("#how-slider", {
		allowTouchMove: true,
		autoHeight: true,
		effect: 'fade',
	});

	$('#how-slider svg [data-value]').on('click', function(event) {
		event.preventDefault();
		var value = $(this).data('value');		
		console.log(value);
		howSlider.slideTo(value);
	});

	$('.back-to-first-slide-how a').on('click', function(event) {
		event.preventDefault();
		howSlider.slideTo(0); // Go to the first slide
	});	


	

	var missionSlider = new Swiper("#mission-slider", {
		allowTouchMove: true,
		autoHeight: false,
		effect: 'fade',
	});

	$('#mission-slider g').on('click', function(event) {
		event.preventDefault();
		var value = $(this).data('value');		
		missionSlider.slideTo(value);
	});

	$('.back-to-first-slide-how a').on('click', function(event) {
		event.preventDefault();
		missionSlider.slideTo(0); // Go to the first slide
	});	


	var projectAdaptable = new Swiper("#adaptable-slider", {
		zoom: true,
		navigation: {
		  nextEl: ".swiper-button-next",
		  prevEl: ".swiper-button-prev",
		},
		scrollbar: {
			el: ".swiper-scrollbar",
			hide: true,
		  },
	  });

	var projectOrigines = new Swiper("#origenes-slider", {
		zoom: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	scrollbar: {
		el: ".swiper-scrollbar",
		hide: true,
		},
	});

	var projectNature = new Swiper("#natures-slider", {
		zoom: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	scrollbar: {
		el: ".swiper-scrollbar",
		hide: true,
		},
	});

	var projectOasis = new Swiper("#oasis-slider", {
		zoom: true,
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	scrollbar: {
		el: ".swiper-scrollbar",
		hide: true,
		},
	});

	//Magnific popup

	loadjs(['https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js','https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.0.1/magnific-popup.min.css'], 'magnific');

	loadjs.ready('magnific', function() {
		$('.popup-modal').magnificPopup({
			type: 'inline',
			preloader: false,
			focus: '#username',
			modal: true
		});
		$(document).on('click', '.popup-modal-dismiss', function (e) {
			e.preventDefault();
			$.magnificPopup.close();
		});
	});
	
	// Sticky Menu
	// https://github.com/yowainwright/stickybits
   
	if( $('#secondary-navigation').length ){

		$("#secondary-navigation").stickybits({ 
			useStickyClasses: true, 
			stickyClass: "secondary-navigation-sticky",
			stuckClass: "secondary-navigation-sticky",
			noStyles: true,
		});

	}

	if( $('header').length ){

		$("header").stickybits({ 
			useStickyClasses: true, 
			stickyClass: "header-sticky",
			stuckClass: "header-sticky",
			noStyles: true
		});
	}
	
	// scroll spy
	// https://github.com/cferdinandi/gumshoe
	
	if( $('#secondary-navigation ul').length ){
		var scrollspy = new Gumshoe('#secondary-navigation ul li a', {
			reflow: true,
			offset: 55
		});
	
	}	

	//
	// video background homepage
	if( $('[data-vbg]').length ){
		$.getScript(
			"https://unpkg.com/youtube-background/jquery.youtube-background.min.js", 
					function () {
					// Selecting button and adding event to it.
					jQuery("[data-vbg]").youtube_background();
				  });
	}
	

    var overlay = document.getElementById('overlay');
	var closeMenu = document.getElementById('close-menu');


	document.getElementById('open-menu') .addEventListener('click', function() {
		overlay.classList.add('show-menu');
	});

	document.getElementById('close-menu').addEventListener('click', function(){
		overlay.classList.remove('show-menu')
	})

	$('.general a').on('click', function(){
		overlay.classList.remove('show-menu')
	})

	// contact form

	if( $('#contactForm').length ){
		// Fetch the contact form
		var contactForm = document.getElementById('contactForm');

		// Add form submission listener
		contactForm.addEventListener('submit', function (event) {
		event.preventDefault();

		$.ajax({
			url: $(this).attr('action'),
			method: $(this).attr('method'),
			data: $(this).serialize(),
			dataType: 'json',
			beforeSend: function () {
			$('#responseMessage').html('');
			},
			success: function (response) {
			if (response.success) {
				$('#responseMessage').html('<div class="alert alert-success">' + response.message + '</div>');
				$('#contactForm')[0].reset();
			} else {
				$('#responseMessage').html('<div class="alert alert-danger">' + response.message + '</div>');
			}
			},
			error: function () {
			$('#responseMessage').html('<div class="alert alert-danger">An error occurred while processing the form.</div>');
			}
		});
		});

	}	

	$(function() {
		$('a[href*=\\#]:not([href=\\#])').on('click', function() {
			var target = $(this.hash);
			target = target.length ? target : $('[name=' + this.hash.substr(1) +']');
			if (target.length) {
				$('html,body').animate({
					scrollTop: target.offset().top - 50
				}, 500);
				return false;
			}
		});
	});
})(jQuery);
